.about .page-header .img {
  max-width: 154px;
  bottom: 30px;
}
.about .anchor {
  margin: 30px auto 38px;
}
.about__block {
  margin: 0;
  padding: 50px 15px;
}
.about__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}

@media screen and (min-width: 768px) {
  .about .page-header .img {
    max-width: 235px;
    bottom: 80px;
  }
  .about .anchor {
    margin: 83px auto 93px;
  }
}
.about01 {
  margin: 0;
  padding: 0 15px 30px;
  background-image: url(../images/about/about_s1_bg_sp.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 100% auto;
}
.about01 .ttl {
  font-size: 23px;
  font-weight: 700;
  text-align: center;
}
.about01 .cont_1 .subTtl {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.about01 .cont_1 .t1 {
  margin-top: 20px;
}
.about01 .cont_1 .img_1 {
  margin-top: 24px;
}
.about01 .t2 {
  margin-top: 40px;
}
.about01 .cont_2 {
  margin-top: 28px;
  padding: 25px 20px;
  border: 1px solid #C0DBEC;
}
.about01 .cont_2 .flexItem + .flexItem {
  margin-top: 18px;
}
.about01 .serviceBox {
  display: flex;
  width: 100%;
}
.about01 .serviceBox figure {
  width: 60px;
}
.about01 .serviceBox .textarea {
  padding-left: 15px;
  width: calc(100% - 60px);
}
.about01 .serviceBox p {
  font-size: 13px;
  line-height: 1.84;
}

@media screen and (min-width: 768px) {
  .about01 {
    padding: 0 15px 80px;
    background-image: url(../images/about/about_s1_bg.png);
    background-size: 1440px auto;
  }
  .about01 .ttl {
    font-size: 35px;
    letter-spacing: 0.05em;
  }
  .about01 .cont_1 {
    margin-top: 60px;
  }
  .about01 .cont_1 .flexBox {
    display: flex;
    justify-content: space-between;
  }
  .about01 .cont_1 .flexBox .flexItem:nth-of-type(1) {
    width: 52%;
  }
  .about01 .cont_1 .flexBox .flexItem:nth-of-type(2) {
    width: 41%;
  }
  .about01 .cont_1 .subTtl {
    margin: 0;
    font-size: 24px;
    text-align: left;
  }
  .about01 .cont_1 .t1 {
    margin-top: 25px;
  }
  .about01 .cont_1 .img_1 {
    margin-top: 0;
  }
  .about01 .t2 {
    margin-top: 54px;
  }
  .about01 .cont_2 {
    margin: 54px auto 0;
    padding: 33px 58px 26px;
    max-width: 966px;
    background-color: #FFF;
  }
  .about01 .cont_2 .flexBox {
    display: flex;
    justify-content: space-between;
    margin: 0 -20px;
  }
  .about01 .cont_2 .flexItem {
    margin: 0;
    padding: 0 20px;
    width: 50%;
  }
  .about01 .cont_2 .flexItem + .flexItem {
    margin-top: 0;
  }
  .about01 .serviceBox {
    display: flex;
    width: 100%;
  }
  .about01 .serviceBox figure {
    width: 106px;
  }
  .about01 .serviceBox .textarea {
    padding-left: 37px;
    width: calc(100% - 106px);
  }
  .about01 .serviceBox p {
    line-height: 1.92;
  }
  .about01 .serviceBox .serviceBox_img_2 {
    width: 120px;
  }
}
.about02 {
  padding: 22px 15px 60px;
  position: relative;
}
.about02::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #F1F6F9;
  position: absolute;
  right: 50%;
  top: 0;
  z-index: -1;
  transform: translate(64px, 68px);
}
.about02 .cont_1 {
  padding: 40px 0 50px;
}
.about02 .cont_1 .flexItem + .flexItem {
  margin-top: 50px;
}
.about02 .cont_1 .ttl {
  margin-top: 18px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.about02 .cont_1 .t1 {
  margin-top: 20px;
}
.about02 .cont_1 .link {
  margin-top: 17px;
}
.about02 .cont_1 .link a {
  margin: 0 auto;
  width: 300px;
  height: 50px;
}
.about02 .cont_2 {
  padding: 30px 20px;
  background-color: #FFF;
  border: 1px solid #C0DBEC;
}
.about02 .cont_2 .img {
  padding: 0 25px;
}
.about02 .cont_2 .ttl {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.about02 .cont_2 .t1 {
  margin-top: 20px;
}
.about02 .cont_2 .link {
  margin-top: 20px;
}
.about02 .cont_2 .link a {
  margin: 0 auto;
  width: 270px;
  height: 50px;
}

@media screen and (min-width: 768px) {
  .about02 {
    padding: 72px 15px 120px;
  }
  .about02::after {
    right: 50%;
    transform: translate(-228px, 0);
  }
  .about02 .lead {
    text-align: center;
  }
  .about02 .cont_1 {
    padding: 15px 0 48px;
  }
  .about02 .cont_1 .flexBox {
    display: flex;
    margin: 0 -20px;
  }
  .about02 .cont_1 .flexItem {
    padding: 0 20px;
    width: 50%;
  }
  .about02 .cont_1 .flexItem + .flexItem {
    margin-top: 0;
  }
  .about02 .cont_1 .box {
    padding: 15px 0;
  }
  .about02 .cont_1 .ttl {
    margin-top: 30px;
    font-size: 24px;
  }
  .about02 .cont_1 .t1 {
    margin-top: 20px;
  }
  .about02 .cont_1 .link {
    margin-top: 32px;
  }
  .about02 .cont_1 .link a {
    margin: 0 auto;
    width: 100%;
    height: 72px;
    max-width: 408px;
  }
  .about02 .cont_2 {
    padding: 63px 54px 55px;
  }
  .about02 .cont_2 .flexBox {
    display: flex;
    margin: 0 -20px;
  }
  .about02 .cont_2 .img {
    width: 40%;
  }
  .about02 .cont_2 .textarea {
    padding-left: 50px;
    width: 60%;
  }
  .about02 .cont_2 .ttl {
    margin-top: 0;
    font-size: 24px;
    text-align: left;
  }
  .about02 .cont_2 .t1 {
    margin-top: 25px;
  }
  .about02 .cont_2 .link {
    margin-top: 30px;
  }
  .about02 .cont_2 .link a {
    margin: 0;
    width: 368px;
    height: 72px;
  }
}
@media screen and (min-width: 992px) {
  .about02 .cont_1 .box {
    padding: 40px;
  }
}
.about03 {
  padding: 60px 15px 30px;
  color: #FFF;
  background-color: #00548B;
  overflow: hidden;
  position: relative;
}
.about03::before, .about03::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  position: absolute;
  z-index: 1;
}
.about03::before {
  width: 120px;
  height: 150px;
  background-image: url(../images/toppage/faq_bg_1.png);
  left: 0;
  top: 0;
  opacity: 0.5;
}
.about03::after {
  width: 300px;
  height: 390px;
  background-image: url(../images/toppage/faq_bg_2.png);
  right: -90px;
  top: 41.5%;
}
.about03 .ttl {
  font-size: 23px;
  font-weight: 700;
  text-align: center;
}
.about03 .lead {
  margin-top: 25px;
}
.about03 .cont_1 {
  position: relative;
  z-index: 2;
}
.about03 .box {
  margin: 30px 0;
  padding: 27px 20px 30px 27px;
  color: #333;
  background-color: #FFF;
  background-image: url(../images/about/line.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: auto 100%;
}
.about03 .box .subTtl {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.about03 .box .t1 {
  margin-top: 13px;
}
.about03 .box .link a {
  margin: 0 auto;
  width: 270px;
  height: 50px;
}

@media screen and (min-width: 768px) {
  .about03 {
    padding: 120px 15px 60px;
  }
  .about03 .ttl {
    font-size: 35px;
  }
  .about03 .lead {
    margin-top: 55px;
    text-align: center;
  }
  .about03 .cont_1 {
    margin-top: 15px;
  }
  .about03 .cont_1 .flexBox {
    display: flex;
    margin: 0 -10px;
  }
  .about03 .cont_1 .flexItem {
    padding: 60px 10px;
    width: 50%;
  }
  .about03 .box {
    margin: 0;
    padding: 40px 35px 50px 45px;
  }
  .about03 .box .explanation {
    height: 300px;
  }
  .about03 .box .subTtl {
    font-size: 24px;
  }
  .about03 .box .t1 {
    margin-top: 20px;
  }
  .about03 .box .link a {
    margin: 0 auto;
    width: 100%;
    height: 72px;
    max-width: 340px;
  }
}
@media screen and (min-width: 992px) {
  .about03::before {
    width: 528px;
    height: 990px;
    left: 0px;
    top: -75px;
  }
  .about03::before .explanation {
    height: 210px;
  }
  .about03::after {
    width: 380px;
    height: 976px;
    right: -20px;
    top: 140px;
  }
  .about03 .cont_1 .flexBox {
    display: flex;
    margin: 0 -60px;
  }
  .about03 .cont_1 .flexItem {
    padding: 60px;
    width: 50%;
  }
}