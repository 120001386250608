.about{

	.page-header{

		.img{
			max-width: 154px;
			bottom: 30px;
		}
	}

	.anchor{
		margin: 30px auto 38px;
	}

	&__block{
		margin: 0;
		padding: 50px 15px;

		.inner{
			margin: 0 auto;
			padding: 0;
			width: 100%;
			max-width: 1100px;
		}
	}
}
@media screen and (min-width:768px){
	.about{

		.page-header{

			.img{
				max-width: 235px;
				bottom: 80px;
			}
		}

		.anchor{
			margin: 83px auto 93px;
		}
	}
}

//----------------------------------------------
.about01{
	margin: 0;
	padding: 0 15px 30px;
	background-image: url(../images/about/about_s1_bg_sp.png);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 100% auto;

	.ttl{
		font-size: 23px;
		font-weight: 700;
		text-align: center;
	}

	.cont_1{

		.subTtl{
			margin-top: 30px;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
		}

		.t1{
			margin-top: 20px;
		}

		.img_1{
			margin-top: 24px;
		}
	}

	.t2{
		margin-top: 40px;
	}

	.cont_2{
		margin-top: 28px;
		padding: 25px 20px;
		border: 1px solid #C0DBEC;

		.flexItem + .flexItem{
			margin-top: 18px;
		}
	}

	.serviceBox{
		display: flex;
		width: 100%;

		figure{
			width: 60px;
		}

		.textarea{
			padding-left: 15px;
			width: calc( 100% - 60px);
		}

		p{
			font-size: 13px;
			line-height: 1.84;
		}
	}
}
@media screen and (min-width:768px){
	.about01{
		padding: 0 15px 80px;
		background-image: url(../images/about/about_s1_bg.png);
		background-size: 1440px auto;

		.ttl{
			font-size: 35px;
			letter-spacing: 0.05em;
		}

		.cont_1{
			margin-top: 60px;

			.flexBox{
				display: flex;
				justify-content: space-between;

				.flexItem:nth-of-type(1){
					width: 52%;
				}
				.flexItem:nth-of-type(2){
					width: 41%;
				}
			}

			.subTtl{
				margin: 0;
				font-size: 24px;
				text-align: left;
			}

			.t1{
				margin-top: 25px;
			}

			.img_1{
				margin-top: 0;
			}

		}

		.t2{
			margin-top: 54px;
		}

		.cont_2{
			margin: 54px auto 0;
			padding: 33px 58px 26px;
			max-width: 966px;
			background-color: #FFF;

			.flexBox{
				display: flex;
				justify-content: space-between;
				margin: 0 -20px;
			}

			.flexItem{
				margin: 0;
				padding: 0 20px;
				width: 50%;
			}

			.flexItem + .flexItem{
				margin-top: 0;
			}
		}

		.serviceBox{
			display: flex;
			width: 100%;
	
			figure{
				width: 106px;
			}

			.textarea{
				padding-left: 37px;
				width: calc( 100% - 106px);
			}

			p{
				line-height: 1.92;
			}

			.serviceBox_img_2{
				width: 120px;
			}
		}

	}
}

//----------------------------------------------
.about02{
	padding: 22px 15px 60px;
	position: relative;

	&::after{
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: #F1F6F9;
		position: absolute;
		right: 50%;
		top: 0;
		z-index: -1;
		transform: translate(64px, 68px);
	}

	.cont_1{
		padding: 40px 0 50px;

		.flexItem + .flexItem{
			margin-top: 50px;
		}

		.ttl{
			margin-top: 18px;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
		}

		.t1{
			margin-top: 20px;
		}

		.link{
			margin-top: 17px;

			a{
				margin: 0 auto;
				width: 300px;
				height: 50px;
			}
		}
	}

	.cont_2{
		padding: 30px 20px;
		background-color: #FFF;
		border: 1px solid #C0DBEC;

		.img{
			padding: 0 25px;
		}

		.ttl{
			margin-top: 20px;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
		}

		.t1{
			margin-top: 20px;
		}

		.link{
			margin-top: 20px;

			a{
				margin: 0 auto;
				width: 270px;
				height: 50px;
			}
		}
	}
}
@media screen and (min-width:768px){
	.about02{
		padding: 72px 15px 120px;

		&::after{
			right: 50%;
			transform: translate(-228px, 0);
		}

		.lead{
			text-align: center;
		}

		.cont_1{
			padding: 15px 0 48px;

			.flexBox{
				display: flex;
				margin: 0 -20px;
			}

			.flexItem{
				padding: 0 20px;
				width: 50%;
			}

			.flexItem + .flexItem{
				margin-top: 0;
			}

			.box{
				padding: 15px 0;
			}

			.ttl{
				margin-top: 30px;
				font-size: 24px;
			}

			.t1{
				margin-top: 20px;
			}

			.link{
				margin-top: 32px;
	
				a{
					margin: 0 auto;
					width: 100%;
					height: 72px;
					max-width: 408px;
				}
			}
		}

		.cont_2{
			padding: 63px 54px 55px;
		
			.flexBox{
				display: flex;
				margin: 0 -20px;
			}

			.img{
				width: 40%;
			}

			.textarea{
				padding-left: 50px;
				width: 60%;
			}

			.ttl{
				margin-top: 0;
				font-size: 24px;
				text-align: left;
			}
	
			.t1{
				margin-top: 25px;
			}

			.link{
				margin-top: 30px;
	
				a{
					margin: 0;
					width: 368px;
					height: 72px;
				}
			}
		}
	}
}
@media screen and (min-width:992px){
	.about02{

		.cont_1{

			.box{
				padding: 40px;
			}
		}
	}
}

//----------------------------------------------
.about03{
	padding: 60px 15px 30px;
	color: #FFF;
	background-color: #00548B;
	overflow: hidden;
	position: relative;

	&::before, &::after{
		content: "";
		display: block;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		position: absolute;
		z-index: 1;
	}

	&::before{
		width: 120px;
		height: 150px;
		background-image: url(../images/toppage/faq_bg_1.png);
		left: 0;
		top: 0;
		opacity: 0.5;
	}

	&::after{
		width: 300px;
    height: 390px;
    background-image: url(../images/toppage/faq_bg_2.png);
    right: -90px;
    top: 41.5%;
	}

	.ttl{
		font-size: 23px;
		font-weight: 700;
		text-align: center;
	}

	.lead{
		margin-top: 25px;
	}

	.cont_1{
		position: relative;
    z-index: 2;
	}

	.box{
		margin: 30px 0;
		padding: 27px 20px 30px 27px;
		color: #333;
		background-color: #FFF;
		background-image: url(../images/about/line.png);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: auto 100%;

		.subTtl{
			font-size: 18px;
			font-weight: 700;
			text-align: center;
		}

		.t1{
			margin-top: 13px;
		}

		.link{
			a{
				margin: 0 auto;
				width: 270px;
				height: 50px;
			}
		}
	}
}
@media screen and (min-width:768px){
	.about03{
		padding: 120px 15px 60px;

		.ttl{
			font-size: 35px;
		}

		.lead{
			margin-top: 55px;
			text-align: center;
		}

		.cont_1{
			margin-top: 15px;

			.flexBox{
				display: flex;
				margin: 0 -10px;
			}

			.flexItem{
				padding: 60px 10px;
				width: 50%;
			}
		}

		.box{
			margin: 0;
			padding: 40px 35px 50px 45px;

            .explanation {
                height: 300px;
              }

			.subTtl{
				font-size: 24px;
			}
	
			.t1{
				margin-top: 20px;
			}
	
			.link{
				a{
					margin: 0 auto;
					width: 100%;
					height: 72px;
					max-width: 340px;
				}
			}
		}
	}
}
@media screen and (min-width:992px){
	.about03{

		&::before{
			width: 528px;
			height: 990px;
			left: 0px;
			top: -75px;

            .explanation {
                height: 210px;
              }
		}
        

		&::after{
			width: 380px;
			height: 976px;
			right: -20px;
			top: 140px;
		}

		.cont_1{

			.flexBox{
				display: flex;
				margin: 0 -60px;
			}

			.flexItem{
				padding: 60px;
				width: 50%;
			}
		}
	}
}